import { Controller } from "stimulus";
import $ from "jquery";
import "jquery.easing";

export default class extends Controller {
  static targets = ["scrollIcon", "targetSection"];

  connect() {
    this.scrollIconTarget.addEventListener("click", () => this.scrollToSection());
  }

  scrollToSection() {
    const targetSection = this.targetSectionTarget
    const smallDeviceOffset = $(window).height() <= 1000 ? 120 : 0
    if (targetSection) {
      $('html, body').stop().animate({
        scrollTop: $(targetSection).offset().top + smallDeviceOffset
      }, 300, 'easeInOutCubic');
    }
  }
}
