import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  declare element: HTMLVideoElement

  connect() {
    useIntersection(this)
  }
  appear() {
    this.element.play()
  }
  disappear() {
    this.element.pause()
  }
  disconnect() {
    if (!this.element.paused) {
      this.element.pause()
    }
  }
}
