import Glide from "@glidejs/glide"

import { Controller } from "stimulus"

export default class extends Controller {
  glide: Glide.Static | null = null
  static values = {
    autoplay: Boolean,
  }

  connect() {
    const glide = new Glide(this.element as HTMLElement, {
      type: "carousel",
      autoplay: this.autoplayValue ? 1000 : false,
      hoverpause: true,
      gap: 20,
      perView: 5,
      focusAt: 0,
      startAt: 0,
      animationDuration: 1000,
      animationTimingFunc: "linear",
      breakpoints: {
        1400: {
          perView: 4,
        },
        992: {
          perView: 3,
        },
        768: {
          perView: 2,
        },
      },
    })
    glide.mount({})
    this.glide = glide
  }
  disconnect() {
    if (this.glide)
      this.glide.destroy()
  }
}
