import { Controller } from "stimulus"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet.beautifymarker/leaflet-beautify-marker-icon.css"
import "leaflet.beautifymarker/leaflet-beautify-marker-icon.js"

export default class extends Controller {
  static values = { addresses: Array }

  connect() {
    const map = L.map("map", {
      center: [51.1642, 10.4541],
      zoom: 5.2,
      zoomControl: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      dragging: false,
      keyboard: false,
      touchZoom: false,
      boxZoom: false
    })
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map)
    // L.Icon.Default.prototype.options.iconSize = [10, 16]
    // L.Icon.Default.prototype.options.shadowSize = [20, 20]
    const options = {
      iconShape: 'doughnut',
      borderWidth: 5,
      borderColor: '#00ABDC'
    }
    for (var i = 0; i < this.addressesValue.length; i++) {
      var marker = L.marker([this.addressesValue[i].latitude, this.addressesValue[i].longitude], {
        icon: L.BeautifyIcon.icon(options)
      }).bindPopup(this.addressesValue[i].name)
        .addTo(map)
    }
  }
}
