import BaseController from "./base_controller"

import { medium } from './themes'

export default class extends BaseController {
  chartOptions() {
    const options: Highcharts.Options = this.jsonChartOptions()
    if (options.xAxis && !("length" in options.xAxis)) {
      options.xAxis.labels ||= {}
      options.xAxis.labels.formatter = function() {
        const currentMonth = new Date().getMonth()
        const isJanuary = currentMonth === 0

        const tooltipText = 'Die Hochrechnung für das aktuelle Jahr wird täglich aktualisiert.'
        if (this.isLast && !isJanuary) {
          return `<span style='color: #fff;' title='${tooltipText}'><strong>*</strong>${this.value}</span>`
        } else {
          return this.value.toString()
        }
      }
    }
    medium(options)

    return options
  }
}
