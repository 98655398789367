import { Controller } from "stimulus";
import Highcharts from "highcharts"
import HighchartsMoreModule from "highcharts/highcharts-more";

HighchartsMoreModule(Highcharts);

export default class extends Controller {
  static values = { chartData: Object };

  connect() {
    this.initializeChart();
  }

  initializeChart() {
    const series = Object.entries(this.chartDataValue).map(([key, value]) => {
      return { name: key, y: value }
    })
    Highcharts.chart(this.element, {
      chart: {
        type: 'pie',
        margin: [60, 60, 60, 60]
      },
      title: {
        text: 'Unternehmensgröße'
      },
      tooltip: {
        valueSuffix: ''
      },
      colors: ['#FF5733', '#33FF57', '#3357FF', '#FF33A6', '#FF8333', '#33FFA6', '#A633FF', '#33FF83'],
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          size: '50%',
          dataLabels: [{
            enabled: true,
            format: '<b>{point.name}</b> ({point.percentage:.1f}%)',
            distance: 20
          }]
        }
      },
      series: [{
        name: 'Unternehmensgrößen',
        colorByPoint: true,
        data: series
      }]
    });

  }
}
