import { Controller } from "stimulus";
import Highcharts from "highcharts"
import HighchartsMoreModule from "highcharts/highcharts-more";

HighchartsMoreModule(Highcharts);

export default class extends Controller {
  static values = { chartData: Object };

  connect() {
    this.initializeChart();
  }

  initializeChart() {
    Highcharts.chart(this.element, {
      watermarkImage: false,
      chart: {
        type: "boxplot",
      },
      credits: { enabled: false },
      title: {
        text: "Gehaltsvergleich"
      },
      yAxis: {
        title: {
          text: "Gehalt (tsd. EUR)",
          minorTickInterval: "auto",
          minorGridLineColor: "#f0f0f0",
          max: null,
          min: 0,
          labels: {}
        },
        labels: {
          formatter() {
            return this.axis.defaultLabelFormatter.call(this).replace('k', '');
          }
        }
      },
      series: this.series(),
      plotOptions: {
        series: {
          boxplot: {
            dataLabels: {
              enabled: true
            },
          },
          groupPadding: 0,
          dataLabels: {
            style: {
              textShadow: false
            },
          }
        }
      },
      xAxis: {
        minRange: 2,
        showFirstLabel: true,
        showLastLabel: true,
        gridLineWidth: 0.5,
        labels: {
          enabled: false,
        }
      }
    })
  }

  series() {
    const myBoxplotData = this.chartDataValue.boxplot_my.boxplot_data.flat();
    const communityBoxplotData = this.chartDataValue.boxplot_community.boxplot_data.flat();

    return [
      myBoxplotData.length > 0 ? {
        name: "Meine Daten",
        data: [{
          x: 0,
          low: 20000,
          q1: 34000,
          median: 50000,
          q3: 64000,
          high: 89000,
        }],
        color: "#802d4a",
        fillColor: "#bf436f",
        showInLegend: true,
        tooltip: {
          valueSuffix: ' €',
          pointFormat: 'Maximum: {point.high}<br/>Oberes Quartil: {point.q3}<br/><b>Median: {point.median}</b><br/>Unteres Quartil: {point.q1}<br/>Minimum: {point.low}<br/>'
        }
      } : undefined,
      {
        id: "data",
        name: "Community Daten",
        data: [{
          x: 1,
          low: communityBoxplotData[0],
          q1: communityBoxplotData[1],
          median: communityBoxplotData[2],
          q3: communityBoxplotData[3],
          high: communityBoxplotData[4],
        }],
        color: "#3c3d3e",
        fillColor: "#5e5d5d",
        showInLegend: true,
        tooltip: {
          valueSuffix: ' €',
          pointFormat: 'Maximum: {point.high}<br/>Oberes Quartil: {point.q3}<br/><b>Median: {point.median}</b><br/>Unteres Quartil: {point.q1}<br/>Minimum: {point.low}<br/>'
        },
      },
      {
        name: 'Ausreißer',
        // data: this.chartDataValue.boxplot_community.scatter_data,
        data: [ // x, y positions where 0 is the first category
          { x: -0.25, y: 17000, name: '' },
          { x: -0.25, y: 99000, name: '' },
          { x: -0.25, y: 15000, name: '' },
          { x: 1.25, y: 98000, name: '' },
          { x: 1.25, y: 95000, name: 'Ausreißer' },
          { x: 1.25, y: 12000, name: 'Ausreißer' }
        ],
        dataLabels: {
          allowOverlap: true,
          align: 'left',
          y: 10,
          x: -100,
          formatter() {
            return this.point.name;
          },
          enabled: true,
          style: {
            color: "#222",
            fontSize: "12px"
          },
        },
        color: "#802d4a",
        type: 'scatter',
        showInLegend: false,
      },
      // {
      //   data: [
      //     myBoxplotData.length > 0 ? {
      //       x: 0,
      //       low: myBoxplotData[0],
      //       q1: myBoxplotData[1],
      //       median: myBoxplotData[2],
      //       q3: myBoxplotData[3],
      //       high: myBoxplotData[4],
      //       name: "Meine Daten",
      //       color: "#802d4a",
      //       fillColor: "#bf436f",
      //       name: "Meine Daten",
      //       showInLegend: true,
      //     } : undefined,
      //     communityBoxplotData.length > 0 ? {
      //       x: 1,
      //       low: communityBoxplotData[0],
      //       q1: communityBoxplotData[1],
      //       median: communityBoxplotData[2],
      //       q3: communityBoxplotData[3],
      //       high: communityBoxplotData[4],
      //       color: "#3c3d3e",
      //       fillColor: "#5e5d5d",
      //       name: "Community Daten",
      //     } : undefined
      //   ],
      //   tooltip: {
      //     valueSuffix: ' €',
      //     pointFormat: 'Maximum: {point.high}<br/>Oberes Quartil: {point.q3}<br/><b>Median: {point.median}</b><br/>Unteres Quartil: {point.q1}<br/>Minimum: {point.low}<br/>'
      //   },
      //   pointWidth: 50,
      //   showInLegend: false,
      // },
      {
        id: 'labels',
        name: 'labels',
        type: 'scatter',
        showInLegend: false,
        data: this.boxplotLabelsData(),
        color: "#811e2f",
        dataLabels: {
          allowOverlap: true,
          align: 'left',
          y: 10,
          x: -50,
          formatter() {
            return this.point.name;
          },
          enabled: true,
          style: {
            color: "#222",
            fontSize: "12px"
          },
        },
        tooltip: {
          enabled: false
        },
        marker: {
          enabled: false
        },
        enableMouseTracking: false,
      },
      // {
      //   id: 'labels',
      //   name: 'labels',
      //   type: 'scatter',
      //   showInLegend: false,
      //   data: this.boxplotLabelsData(),
      //   color: "#3c3d3e",
      //   dataLabels: {
      //     allowOverlap: true,
      //     align: 'left',
      //     y: 10,
      //     x: 50,
      //     formatter() {
      //       return new Intl.NumberFormat('de-DE', {
      //         style: 'currency',
      //         currency: 'EUR',
      //         minimumFractionDigits: 1,
      //         maximumFractionDigits: 4,
      //         minimumSignificantDigits: 1,
      //         maximumSignificantDigits: 4
      //       }).format(Math.round(this.y * 100) / 100);
      //     },
      //     enabled: true,
      //     style: {
      //       color: "#222",
      //       fontSize: "12px"
      //     },
      //   },
      //   tooltip: {
      //     enabled: false
      //   },
      //   marker: {
      //     enabled: false
      //   },
      //   enableMouseTracking: false,
      // }
    ].filter(Boolean)
  }

  boxplotLabelsData() {
    let data = []
    let labels = ['Antenne', '3. Quartil', 'Median', '2. Quartil', 'Antenne']
    for (let i = 0; i < this.chartDataValue.boxplot_community.boxplot_data[0].length; i++) {
      data.push(
        {
          x: 1,
          y: this.chartDataValue.boxplot_community.boxplot_data[0][i],
          name: labels[i],
          dataLabels: {
            x: -70,
            y: 10,
            style: {
              color: "#222",
              fontSize: "12px"
            },
            enabled: true,
            formatter() {
              return this.point.name;
            }
          }
        }
      )
    }
    return data
  }

  boxplotMyLabelsData() {
    let data = []
    for (let i = 0; i < this.chartDataValue.boxplot_my.boxplot_data[0].length; i++) {
      data.push(
        {
          x: 0,
          y: this.chartDataValue.boxplot_my.boxplot_data[0][i],
        }
      )
    }
    return data
  }
}
