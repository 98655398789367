import { Controller } from "stimulus";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

export default class extends Controller {
  static values = { chartData: Object }

  connect() {
    this.initializeChart();
  }

  initializeChart() {
    const data = this.chartDataValue;
    const categories = Object.keys(data['data']);
    const community_data = Object.values(data['data']).map(el => el.count);
    categories.pop();
    community_data.pop();

    const series = [
      {
        name: '',
        data: community_data,
        color: "#9e47ef",
      },
    ];

    Highcharts.chart(this.element, {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Source Sans Pro, sans-serif'
        }
      },
      xAxis: {
        categories: categories,
        labels: { style: { color: '#fff' } },
        title: { text: null },
      },
      plotOptions: {
        bar: { dataLabels: { enabled: false } },
        series: { stacking: 'normal' }
      },
      yAxis: {
        max: null,
        labels: {
          style: { color: '#fff' }
        },
        tickColor: '#999',
        gridLineColor: '#999',
        gridLineWidth: 1,
        gridZIndex: 999,
        title: { text: null },
      },
      title: {
        text: 'Abschluss',
        style: { color: '#fff' }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
        }
      },
      legend: { enabled: false },
      series: series,
    });
  }
}
