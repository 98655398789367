import { Controller } from "stimulus";
import Highcharts from "highcharts"
import HighchartsMoreModule from "highcharts/highcharts-more";

HighchartsMoreModule(Highcharts);

export default class extends Controller {
  static values = { chartData: Object };

  connect() {
    this.initializeChart();
  }

  initializeChart() {
    const maleData = Object.values(this.chartDataValue["Männlich"]);
    const femaleData = Object.values(this.chartDataValue["Weiblich"]);
    const categories = Object.keys(this.chartDataValue["Männlich"]);
    const max = Math.max(...maleData, ...femaleData) + 20;
    Highcharts.chart(this.element, {
      chart: {
        type: 'bar',
        spacingTop: 40,
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Source Sans Pro, sans-serif'
        }
      },
      title: {
        text: 'Altersverteilung der Mitarbeiter in %',
        color: '#000',
        y: -10,
      },
      plotOptions: {
        series: {
          stacking: "normal",
          pointWidth: 10,
          dataLabels: {
            enabled: true,
            inside: false,
            formatter() {
              return Math.abs(this.y) + '%'; // Ensure correct capitalization
            },
            style: {
              color: '#000',
            },
          },
        }
      },
      tooltip: {
        shared: true
      },
      yAxis: {
        plotBands: [{
          color: "rgba(0,0,0,0.0)",
          from: -max,
          to: 0,
          zIndex: 2,
          label: {
            style: { fontSize: '18px', color: '#aaa' },
            text: "Männlich",
            verticalAlign: 'top',
            y: -10,
          }
        }, {
          color: "rgba(0,0,0,0.0)",
          from: 0,
          to: max,
          zIndex: 2,
          label: {
            style: { fontSize: '18px', color: '#aaa' },
            text: "Weiblich",
            verticalAlign: 'top',
            y: -10,
          },
        }],
        max: max,
        plotLines: [{
          color: '#aaa',
          width: 1,
          value: 0,
          zIndex: 5,
        }],
        min: -max,
        allowDecimals: false,
        labels: {
          formatter() {
            return Math.abs(this.value) + '%';
          },
          style: {
            color: '#000',
          },
        },
        tickColor: '#ddd',
        gridLineColor: '#ddd',
        gridLineWidth: 1,
        gridZIndex: 1,
        title: {
          text: null
        },
        accessibility: {
          description: 'Percentage population',
          rangeDescription: 'Range: 0 to 5%'
        }
      },
      xAxis: [{
        categories: categories,
        reversed: false,
        labels: {
          step: 1
        },
        accessibility: {
          description: 'Age (male)'
        }
      }],
      legend: {
        margin: 60,
        align: 'center'
      },
      series: [{
        name: 'Männlich',
        data: maleData,
        color: "#661a24",
      }, {
        name: 'Weiblich',
        data: femaleData,
        color: "#b22e40",
      }]
    });
  }
}
