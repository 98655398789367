import { Controller } from "stimulus";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import merge from 'lodash/merge';

// Initialize Highcharts-more module
HighchartsMore(Highcharts);

// Config from your Vue project
const Config = {
  colors: {
    own: ["#5cb64d", "#408135", '#8ecc83'],
    community: ["#9e47ef", "#5c2f84", "#522a76", "#492569", "#40205c", "#371c4f", "#2e1742", "#241234", "#1b0e27", "#6c4390", "#7c589c", "#8c6da8", "#9d82b5", "#ad97c1", "#bdabcd", "#b16bf2", "#bb7ef3"]
  },
  baseChart(percent, series, chart) {
    const base = {
      title: { text: null },
      credits: { enabled: false },
      xAxis: {
        title: { text: null }
      },
      tooltip: {
        pointFormat: percent ?
          `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>` :
          `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>`
      },
      yAxis: {
        title: { text: null },
        allowDecimals: false,
        labels: {
          format: percent ? "{value}%" : "{value}"
        },
        plotLines: [],
      },
      legend: { enabled: false },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        }
      },
      series
    };
    return merge(base, chart);
  },
  chartTemplates: {
    dataLabels(percent) {
      return {
        enabled: true,
        padding: 0,
        inside: false,
        formatter() {
          const y = Math.round(Math.abs(this.y) * 10) / 10;
          return y === 0 ? "" : (percent ? y + "%" : y);
        },
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: "1px #fff",
          color: '#333'
        }
      };
    },
    unstackedTooltip(percent) {
      return {
        shadow: false,
        borderRadius: 0,
        backgroundColor: 'none',
        borderWidth: 0,
        useHTML: true,
        pointFormatter() {
          const value = this.y ? Math.abs(this.y) : 0;
          return percent ?
            `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${value}%</b><br/>` :
            `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${value}</b><br/>`;
        }
      };
    }
  }
};

export default class extends Controller {
  static values = { chartData: Object }

  connect() {
    this.initializeChart();
  }

  initializeChart() {
    if (typeof this.chartDataValue !== 'object' || this.chartDataValue === null) {
      console.error("chartDataValue is not an object or is null");
      return;
    }
    const { data, benchmark_data, average_age } = this.chartDataValue;
    const maleKey = data["Male"] ? "Male" : "Männlich";
    const femaleKey = data["Female"] ? "Female" : "Weiblich";
    const categories = Object.keys(data[maleKey]);
    const maleData = Object.values(data[maleKey]).map(el => -el.percentage);
    const femaleData = Object.values(data[femaleKey]).map(el => el.percentage);
    const max = Math.max(...maleData.map(Math.abs), ...femaleData) + 10;

    const series = [
      // {
      //   name: 'Männlich (Eigene)',
      //   data: maleData,
      //   color: Config.colors.own[0],
      //   stack: 'eigene'
      // },
      // {
      //   name: 'Weiblich (Eigene)',
      //   data: femaleData,
      //   color: Config.colors.own[1],
      //   stack: 'eigene'
      // },
      {
        name: 'Männlich (Alle)',
        data: maleData,
        color: Config.colors.community[0],
        stack: 'alle'
      },
      {
        name: 'Weiblich (Alle)',
        data: femaleData,
        color: Config.colors.community[1],
        stack: 'alle'
      }
    ];

    Highcharts.chart(this.element, Config.baseChart(false, series, {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Source Sans Pro, sans-serif'
        }
      },
      xAxis: {
        categories: categories.map(el => el.replace("<", "&lt;")),
        labels: { style: { color: '#fff' } }
      },
      tooltip: Config.chartTemplates.unstackedTooltip(false),
      plotOptions: {
        bar: { dataLabels: { enabled: false } },
        series: { stacking: 'normal' }
      },
      yAxis: {
        plotBands: [{
          color: "rgba(0,0,0,0.0)",
          from: -max,
          to: 0,
          zIndex: 2,
          label: {
            style: { fontSize: '18px', color: '#fff' },
            text: "Männlich",
            verticalAlign: 'top',
            y: -10,
          }
        }, {
          color: "rgba(0,0,0,0.0)",
          from: 0,
          to: max,
          zIndex: 2,
          label: {
            style: { fontSize: '18px', color: '#fff' },
            text: "Weiblich",
            verticalAlign: 'top',
            y: -10,
          },
        }],
        max: max,
        plotLines: [{
          color: '#aaa',
          width: 1,
          value: 0,
          zIndex: 5,
        }],
        min: -max,
        allowDecimals: false,
        labels: {
          formatter() {
            return Math.abs(this.value);
          },
          style: {
            color: '#fff',
          }
        },
        tickColor: '#999',
        gridLineColor: '#999',
        gridLineWidth: 1,
        gridZIndex: 999
      },
      title: {
        text: 'Demographie',
        style: { color: '#fff' }
      },
      subtitle: {
        text: `Ø-Alter ${average_age}`,
        style: { color: '#fff' }
      },
    }));
  }
}
