import Highcharts from "~/utils/highcharts"

const axis = (axis: Highcharts.XAxisOptions | Highcharts.YAxisOptions) => {
  axis.labels ||= {}
  axis.labels.style ||= {}
  axis.lineColor = "#ddd"
  axis.tickColor = "#eee"
  axis.gridLineColor = "#eee"
  axis.labels.style.color = "#fff"
  axis.labels.style.fontSize = "16px"
  if (axis.title) {
    axis.title.style ||= {}
    axis.title.style.color = "#fff"
  }
}

const medium = (options: Highcharts.Options) => {
  options.chart ||= {}
  options.chart.backgroundColor = "rgba(0,0,0,0)"
  options.plotOptions ||= {}
  options.plotOptions.column ||= {}
  options.plotOptions.column.borderColor = "#555"
  options.xAxis ||= {}
  options.legend ||= {}
  options.legend.itemStyle ||= {}
  options.legend.itemStyle.color = '#fff'
  options.legend.itemStyle.fontWeight = 'normal'
  options.legend.itemStyle.fontSize = '16px'
  // options.colors = ["#BF436F", "#13414D", "#bf7530", "#30a2bf"]
  // bessere lesbarkeit
  options.colors = ["#BF436F", "#13414D", "#e09651", "#30a2bf"]
  if ("length" in options.xAxis) options.xAxis.forEach((ax) => axis(ax))
  else axis(options.xAxis)
  options.yAxis ||= {}
  if ("length" in options.yAxis) {
    options.yAxis.forEach((ax) => axis(ax))
  } else {
    axis(options.yAxis)
  }
}

export { medium }
