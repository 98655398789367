import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    duration: Number
  }
  static targets = ['items']
  declare readonly itemsTargets: HTMLElement[]
  declare readonly durationValue: number
  connect() {
    this.itemsTargets.forEach(((word, index) => {
      setTimeout(() => { word.classList.add('show') }, index * this.durationValue)
    }))
  }
}
