import { Controller } from "stimulus";
import { useIntersection } from "stimulus-use";
import Wordcloud from "wordcloud";

type TagCloudData = {
  text: string;
  weight: number;
};

export default class extends Controller {
  static values = {
    data: Array,
  };

  declare readonly dataValue: TagCloudData[];

  stop: () => void = () => { };
  isVisible = false;

  connect() {
    const [_, stop] = useIntersection(this, { threshold: 0 });
    this.stop = stop;
  }

  appear() {
    this.stop();

    const domElement = this.element as HTMLElement;
    const list = this.dataValue.map((item: TagCloudData) => [item.text, item.weight * 3]);

    const options: any = {
      minSize: 5,
      backgroundColor: "transparent",
      color() {
        const colors = ["#e9ecef", "#bf436f", "#2a8ea9"];
        return colors[Math.floor(Math.random() * colors.length)];
      },
      fontFamily: "Source Sans Pro, Arial, sans-serif",
      list,
      rotateRatio: 0.2,
      rotationSteps: 2,
    };

    Wordcloud(domElement, options);
  }
}
